//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handleTaskDeleteSign } from '@/api/modular/flowable/handleTaskManage'
import { flowableInstanceDeleteSignUserSelector } from '@/api/modular/flowable/instanceManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      spinningLoading: false,
      recordData: {},
      userList: [],
      form: this.$form.createForm(this)
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    open (record) {
      this.recordData = record
      this.visible = true
      this.getDeleteSignUserSelector()
    },
    /**
     * 獲取加簽人員
     */
    getDeleteSignUserSelector () {
      const params = {
        id: this.recordData.procIns.id
      }
      flowableInstanceDeleteSignUserSelector(params).then((res) => {
        this.userList = res.data
      })
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          values.taskId = this.recordData.id
          this.confirmLoading = true
          handleTaskDeleteSign(values).then((res) => {
            if (res.success) {
              this.$message.success('減簽成功')
              this.returnPage()
            } else {
              this.$message.error(res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        }
      })
    },
    /**
     * 返回並清空界面緩存
     */
    returnPage () {
      this.handleCancel()
      this.$emit('close')
    },
    /**
     * 關閉
     */
    handleCancel () {
      this.recordData = {}
      this.form.resetFields()
      this.visible = false
    }
  }
}
